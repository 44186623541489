<template>
  <div>
    <div v-show="files.length == 0" ref="file">
      <a @click.prevent="openFileInput" href>
        <img :src="img" @error="replaceByDefault" class="image is-128x128" />
      </a>

      <input @change="croppie" type="file" style="display: none" />
    </div>

    <div v-show="files.length > 0">
      <vue-croppie
        ref="croppieRef"
        :enableOrientation="true"
        :boundary="{ width: 300, height: 300 }"
        :viewport="{ width: 250, height: 250, type: 'square' }"
        :showZoomer="false"
      >
      </vue-croppie>

      <button @click="crop" class="button is-primary" type="button">
        Salvar
      </button>
    </div>
  </div>
</template>
<script>
const reader = new FileReader();
export default {
  name: "Upload",
  props: ["id", "prefix"],
  data() {
    return {
      files: "",
      cropped: "",
    };
  },
  computed: {
    img: function () {
      if (this.cropped) return window.URL.createObjectURL(this.cropped);
      if (this.id)
        return `https://maxagenda.sfo2.digitaloceanspaces.com/oftalmomax/${this.prefix}_${this.id}.png`;
      return "";
    },
    runUpload() {
      return this.id && this.cropped;
    },
  },
  watch: {
    runUpload: function (newValue) {
      if (newValue) {
        this._upload();
      }
    },
  },
  methods: {
    openFileInput: function () {
      this.$refs.file.querySelector("input").click();
    },
    croppie(e) {
      this.files = e.target.files || e.dataTransfer.files;
      if (!this.files.length) return;

      //let reader = new FileReader();
      reader.onload = (e) => {
        this.$refs.croppieRef.bind({
          url: e.target.result,
        });
      };

      reader.readAsDataURL(this.files[0]);
    },
    crop() {
      const self = this;
      // Options can be updated.
      // Current option will return a blob version of the uploaded image with a size of 250px X 250px.
      const options = {
        type: "blob",
        size: { width: 250, height: 250 },
        format: "png",
      };
      self.$refs.croppieRef.result(options, (output) => {
        this.cropped = output;
        //self.$set(self, "img", window.URL.createObjectURL(output));
        self.files = [];
      });
    },
    _upload() {
      const fd = new FormData();
      fd.append("file_name", `${this.prefix}_${this.id}`);
      fd.append("file", this.cropped);

      this.$http.post(`${process.env.VUE_APP_URI}upload`, fd).then(() => {
        this.files = [];
      });
    },
    replaceByDefault(e) {
      e.target.src = require("@/assets/img.png");
    },
  },
};
</script>